import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";

function Home() {
  return (
    <>
      <Box style={{ flex: 1, margin: 40 }}>
        <Header />
        <Box style={{ flex: 1, display: "flex" }}>
          <Card style={{ flex: 1 }}>
            <CardContent>
              <Navigation />
              <Typography>This is my little homepage...</Typography>
              <Typography>Well it's not more then this...</Typography>
              <Typography>
                But it's a react app. And that's something i guess.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default Home;
