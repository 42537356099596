import { Box, Button, ButtonGroup } from "@mui/material";

function Navigation() {
  return (
    <ButtonGroup variant="text" aria-label="outlined primary button group">
      <Button href="http://dev.evul.nu">Dev</Button>
      <Button href="http://github.com/AndreasBrostrom">GitHub</Button>
    </ButtonGroup>
  );
}

export default Navigation;
