import { Box, Typography } from "@mui/material";

function Header() {
  return (
    <Box sx={{ flex: 1, display: "flex", marginBottom: 2 }}>
      <Typography
        variant="h1"
        sx={{ fontSize: 40, fontWeight: 700, color: "#dbdbdb" }}
      >
        evul.nu
      </Typography>
    </Box>
  );
}

export default Header;
